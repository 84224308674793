<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">
        {{ title }} ({{ models.length }})
        <b-button
          slot="right"
          @click="add()"
          class="is-primary is-pulled-right"
          icon-left="plus"
        >
          ເພີ່ມໃໝ່
        </b-button>
      </h1>
      <card-component class="has-table has-mobile-sort-spaced">
        <b-table
          :loading="isLoading"
          :paginated="perPage < models.length"
          :per-page="perPage"
          :striped="true"
          :hoverable="true"
          :data="models"
          :current-page.sync="currentPage"
          :pagination-rounded="true"
        >
          <b-table-column label="#" v-slot="props">
            {{ props.index+(currentPage>1?(perPage*currentPage-perPage):0)+1 }}
          </b-table-column>
          <b-table-column label="ລະຫັດ" field="code" sortable v-slot="props">
            {{ props.row.code }}
          </b-table-column>
          <b-table-column label="ຊື່" field="name" sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column label="ເບີໂທ" field="tel" sortable v-slot="props">
            {{ props.row.tel }}
          </b-table-column>
          <b-table-column
            label="ເປີເຊັນ"
            field="percent"
            sortable
            v-slot="props"
          >
            {{ props.row.percent }} %
          </b-table-column>
          <b-table-column label="ແຂວງ" field="name" sortable v-slot="props">
            {{ props.row.district.province.name }}
          </b-table-column>
          <b-table-column label="ເມືອງ" field="name" sortable v-slot="props">
            {{ props.row.district.name }}
          </b-table-column>
          <b-table-column
            custom-key="actions"
            cell-class="is-actions-cell"
            v-slot="props"
          >
            <div class="buttons is-right">
              <b-button
                class="is-small is-warning"
                @click="edit(props.row.id)"
                icon-left="pencil"
              >
              </b-button>
              <b-button
                class="is-small is-danger"
                @click="remove(props.row)"
                icon-left="trash-can"
              >
              </b-button>
            </div>
          </b-table-column>

          <empty-table v-if="models.length == 0" :isLoading="isLoading" />
        </b-table>
      </card-component>
    </section>

    <div
      class="modal is-active mobile-compact"
      v-if="isShowModal && model"
      style="width: auto"
    >
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ title }}</p>
          <button class="delete" aria-label="close" @click="closeModal()" />
        </header>
        <section class="modal-card-body">
          <b-tabs v-model="activeTab" type="is-boxed">
            <b-tab-item label="ຂໍ້ມູນທົ່ວໄປ">
              <b-field label="ລະຫັດສາຂາ" horizontal>
                <b-input
                  v-model="model.code"
                  placeholder="ລະຫັດສາຂາ"
                  required
                />
                <b-input v-model="model.tel" placeholder="ເບີໂທ" required />
              </b-field>
              <b-field label="ຊື່ສາຂາ" horizontal>
                <b-input v-model="model.name" placeholder="ຊື່ສາຂາ" required />
              </b-field>
              <b-field label="ປະເພດ" class="has-check" horizontal>
                <radio-picker
                  v-model="model.type"
                  :options="{
                    COD: 'COD',
                    DESTINATION: 'ປາຍທາງ',
                    CODDESTINATION: 'ປາຍທາງ + COD',
                  }"
                />
              </b-field>
              <b-field label="ແຂວງເມືອງບ້ານ" horizontal>
                <b-select
                  v-if="model.district"
                  v-model="model.district.province"
                  placeholder="ແຂວງ"
                  required
                >
                  <option
                    v-for="(province, index) in provinces"
                    :key="index"
                    :value="province"
                  >
                    {{ province.name }}
                  </option>
                </b-select>
                <b-select
                  v-model="model.district"
                  placeholder="ເມືອງ"
                  required
                  @input="getDistrictLocation()"
                >
                  <option
                    v-for="(district, index) in getDistricts()"
                    :key="index"
                    :value="district"
                  >
                    {{ district.name }}
                  </option>
                </b-select>
                <b-input v-model="model.village" placeholder="ບ້ານ" required />
              </b-field>

              <b-field label="ເປີເຊັນ (%)" horizontal>
                <b-input
                  v-model="model.percent"
                  placeholder="ເປີເຊັນ (%)"
                  type="number"
                  :max="100"
                  :min="0"
                  required
                />
              </b-field>

              <b-field label="ທີ່ຢູ່" horizontal>
                <b-input
                  type="textarea"
                  :rows="2"
                  v-model="model.address"
                  placeholder="ທີ່ຢູ່"
                />
              </b-field>
              <b-field label="ສະຖານະ" horizontal>
                <radio-picker
                  v-model="model.status"
                  :options="{
                    ACTIVE: 'ເປີດ',
                    INACTIVE: 'ປິດ',
                  }"
                />
              </b-field>
              <GmapMap
                :center="center"
                :zoom="15"
                map-type-id="terrain"
                style="width: 100%; height: 300px"
                @click="onClickMap"
              >
                <GmapMarker
                  :position="position"
                  :clickable="true"
                  :draggable="true"
                />
              </GmapMap>
            </b-tab-item>
            <b-tab-item label="ຮູບພາບ">
              {{ uploadPercent }}
              <b-field>
                <b-upload v-model="images" multiple drag-drop>
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon icon="upload" size="is-large"> </b-icon>
                      </p>
                      <p>ລາກຮູບມາວາງຢູ່ນີ້</p>
                    </div>
                  </section>
                </b-upload>
                <div v-if="model.branch_image">
                  <b-carousel>
                    <b-carousel-item
                      v-for="(file, index) in model.branch_image"
                      :key="index"
                    >
                      <section class="hero">
                        <div class="hero-body has-text-centered">
                          <button
                            style="background-color: darkred; margin-top: -20px"
                            class="delete is-danger"
                            aria-label="delete"
                            :id="
                              file.name +
                              ', ' +
                              index +
                              ', ' +
                              model.id +
                              ', ' +
                              file.id
                            "
                            @click="
                              deleteImage(file.name, index, model.id, file.id)
                            "
                          />
                          <img
                            width="100%"
                            :src="
                              'https://kkl-space.sgp1.digitaloceanspaces.com/' +
                              file.name
                            "
                            :alt="file.type"
                          />
                        </div>
                      </section>
                    </b-carousel-item>
                  </b-carousel>
                </div>
                <div style="padding-left: 1em">
                  <ul>
                    <li v-for="(file, index) in images" :key="index">
                      {{ index == 0 ? "ໜ້າປົກ" : "ຮູບ " + index }}
                      <span class="tag is-primary">
                        {{ file.name }}
                        <button
                          class="delete is-small"
                          type="button"
                          @click="deleteDropFile(index)"
                        ></button>
                      </span>
                    </li>
                  </ul>
                </div>
              </b-field>
            </b-tab-item>
          </b-tabs>
        </section>
        <footer class="modal-card-foot">
          <b-button
            class="is-success"
            slot="right"
            @click="save()"
            icon-left="check"
          >
            ບັນທຶກ
          </b-button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import { get } from "vuex-pathify";
import CardComponent from "@/components/CardComponent";
import EmptyTable from "../components/EmptyTable.vue";
import RadioPicker from "@/components/RadioPicker";
import axios from "axios";
const route = "branches";
export default {
  name: "Branches",
  components: { CardComponent, EmptyTable, RadioPicker },
  computed: {
    user: get("user"),
  },
  data() {
    return {
      activeTab: 0,
      currentPage: 1,
      perPage: 20,
      title: "ສາຂາ",
      model: [],
      models: [],
      images: [],
      isShowModal: false,
      isLoading: false,
      provinces: [],
      alldistricts: [],
      districts: [],
      center: { lat: 17.979454, lng: 102.638114 },
      position: { lat: 17.979454, lng: 102.638114 },
      uploadPercent: null,
    };
  },
  mounted() {
    Axios.get("provinces").then((res) => {
      this.provinces = res;
    });
    Axios.get("districts").then((res) => {
      this.alldistricts = res;
    });
    this.get();
  },
  methods: {
    getDistrictLocation() {
      if (!this.model.id) {
        console.log("Add new barnach");
        console.log(this.model.district.name);
        Axios.get(
          `${route}/district-location/${this.model.district.name}`
        ).then((res) => {
          if (res.length > 0) {
            const locate = res[0].geometry.coordinates;
            this.center = { lat: locate[1], lng: locate[0] };
            this.position = { lat: locate[1], lng: locate[0] };
          }

          console.log(res);
        });
      } else {
        console.log("Edit old barnach");
      }
    },
    onupload(e) {
      console.log(e);
    },
    deleteDropFile(index) {
      this.images.splice(index, 1);
      // Axios.delete("branchimage/" + this.images[index].id);
    },
    getDistricts() {
      return this.model.province
        ? this._.filter(
            this.alldistricts,
            (d) => d.province_id == this.model.province.id
          )
        : this.alldistricts;
    },
    onClickMap(e) {
      this.position = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };
      console.log(this.position);
    },
    get() {
      this.isLoading = true;
      Axios.get(route)
        .then((r) => {
          this.isLoading = false;
          this.models = r;
          this.$store.set("branches", r);
        })
        .catch((e) => {
          this.isLoading = false;
          console.error(e);
        });
    },
    add() {
      this.model = {
        status: "ACTIVE",
        type: "COD",
        district: {
          province: this.provinces[0],
        },
      };
      this.isShowModal = true;
    },
    edit(item) {
      this.model = this.models.filter((i) => i.id === item)[0];
      item = this.model;
      if (item.latitude && item.longitude) {
        this.position = {
          lat: item.latitude,
          lng: item.longitude,
        };
        this.center = this.position;
      }
      this.isShowModal = true;
    },
    remove(item) {
      this.$buefy.dialog.confirm({
        title: "ຢືນຢັນການລຶບຂ້ໍມູນ",
        message: "ທ່ານຕ້ອງການ <b>ລຶບ</b> ແທ້ບໍ?",
        confirmText: "ແມ່ນ, ລຶບ",
        cancelText: "ບໍ່",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          Axios.delete(`${route}/${item.id}`).then((r) => {
            console.log(r);
            this.get();
          });
        },
      });
    },
    save() {
      let req;
      this.model.latitude = this.position.lat;
      this.model.longitude = this.position.lng;
      if (!this.model.id) {
        req = Axios.put(route, this.model);
      } else {
        req = Axios.post(route, this.model);
      }
      req.then((res) => {
        this.model = res;
        if (res && this.images) {
          for (let index = 0; index < this.images.length; index++) {
            const image = this.images[index];
            let formData = new FormData();
            formData.append("file", image);
            axios
              .post("upload", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: this.progressEvent,
              })
              .then((r) => {
                //console.log("uploadedImage", r);
                const uploadedImage = r;

                Axios.put("branchimage", {
                  branch_id: this.model.id,
                  type: index == 0 ? "cover" : "slide",
                  name: uploadedImage.key,
                }).then((rr) => {
                  //console.log(rr);
                  this.models.forEach((v, i) => {
                    if (v.id === rr.branch_id) {
                      this.models[i].branch_image.push(rr);
                    }
                  });
                  //{"id":8,"type":"cover","name":"kkphontong.jpeg","branch_id":1}
                  // console.log(rr);
                });
                this.images = [];
              })
              .catch((err) => {
                this.$buefy.toast.open({
                  message: `Error: ${err.message}`,
                  type: "is-danger",
                });
              });
          }
        }
        this.closeModal();
        this.get();
      });
    },
    deleteImage(filename, index, id, imageId) {
      axios
        .delete(`delete-image/image/${imageId}`, {
          data: { fileKey: filename },
        })
        .then((res) => {
          this.models.forEach((v, i) => {
            if (v.id === id) {
              this.models[i].branch_image = res;
            }
          });

          this.model.branch_image = res;
          //console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeModal() {
      this.isShowModal = false;
    },
    progressEvent(progressEvent) {
      this.uploadPercent = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
    },
  },
};
</script>
